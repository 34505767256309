




































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import ContractingStatusModerateDialogViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/contracting-status-moderate/contracting-status-moderate-dialog-view-model';

@Component({
  name: 'ContractingStatusModerateDialog',
  components: {
    ContractingStatusModerateSignYourContract: () => import('./ContractingStatusModerateSignYourContract.vue'),
    ContractingStatusModerateSignatureReceived: () => import('./ContractingStatusModerateSignatureReceived.vue'),
    ContractingStatusModerateRequestReceived: () => import('./ContractingStatusModerateRequestReceived.vue'),
  },
})
export default class ContractingStatusModerateDialog extends Vue {
  @PropSync('investorGoalId', { type: String })
  synced_investor_goal_id!: string;

  @PropSync('goalName', { type: String })
  synced_goal_name!: string;

  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  @PropSync('ContractingStatus', { type: String })
  synced_contracting_status!: string;

  contracting_status_moderate_wealth_model = Vue.observable(
    new ContractingStatusModerateDialogViewModel(this),
  );

  mounted() {
    this.contracting_status_moderate_wealth_model.setContractingStatus(
      this.synced_contracting_status,
    );
  }

  closeModal() {
    this.synced_is_open = false;
  }
}
